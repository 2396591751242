<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white login-confirmation-on"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #fff4d0"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-30 pt-10">
          <div class="container">
            <div
              class="ml-lg-20 ml-md-20 text-lg-left text-md-left text-center"
            >
              <div
                class="container d-inline-block w-auto bg-white py-2 mb-5"
                style="border-radius: 24px"
              >
                <img
                  src="media/logos/main-logo.png"
                  class="max-h-35px"
                  alt=""
                />
              </div>
              <h3
                class="font-weight-bolder font-size-h6 font-size-h3-lg d-none d-md-block d-lg-block"
              >
                Selamat Datang di Grafindo Dokumen
              </h3>
              <p class="d-none d-md-block d-lg-block">
                Layanan Dokumen Karyawan Lingkungan PT Grafindo Media Pratama
              </p>
            </div>
          </div>
        </div>
        <div class="d-none d-md-block d-lg-block">
          <div
            class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
            :style="{
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: `75%`,
              backgroundPosition: `center`,
            }"
          ></div>
        </div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <div class="login-form login-confirmation">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_confirmation_form"
              ref="kt_login_confirmation_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Password confirmation ?
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Enter your new password
                </p>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >New Password</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    name="newPassword"
                    ref="newPassword"
                    v-model="form.newpassword"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >Confirmation password</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    name="confirmationPassword"
                    ref="confirmationPassword"
                    v-model="form.confirmPassword"
                  />
                </div>
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  id="kt_login_forgot_submit"
                  ref="kt_login_forgot_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                  Reset Password
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="$router.push('/login')"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import {
  VERIFY_FORGOT,
  RESET_PASSWORD,
} from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "login-2",
  data() {
    return {
      state: "confirmation",
      form: {},
      currentToken: {},
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),
    token() {
      return this.$route.query.t;
    },
    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-8.svg"
      );
    },
  },
  mounted() {
    this.verifyToken();
    const confirmation_form = KTUtil.getById("kt_login_confirmation_form");
    this.fv3 = formValidation(confirmation_form, {
      fields: {
        newPassword: {
          validators: {
            notEmpty: {
              message: "New Password is required",
            },
          },
        },
        confirmationPassword: {
          validators: {
            identical: {
              compare: function () {
                return document.querySelector('[name="newPassword"]').value;
              },
              message: "The password and its confirm are not the same",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv3.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Please, provide correct data!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
    });

    this.fv3.on("core.form.valid", () => {
      const submitButton = this.$refs["kt_login_forgot_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      const _data = {
        password: this.form.newpassword,
        verify_password: this.form.confirmPassword,
        token: this.token,
      };
      this.$store
        .dispatch(RESET_PASSWORD, _data)
        .then(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );

          Swal.fire({
            title: "Berhasil mengatur ulang kata sandi",
            text: "Silahkan login kembali menggunakan kata sandi terbaru",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push("/login");
            }
          });
        })
        .catch(() => {
          Swal.fire({
            title: "Gagal mengatur ulang kata sandi",
            text: this.errors,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    });
  },
  methods: {
    verifyToken() {
      const overlay = this.$loadingOverlay.show();
      const params = new URLSearchParams();
      params.append("token", this.token);
      this.$store
        .dispatch(VERIFY_FORGOT, params.toString())
        .then((response) => {
          overlay.hide();
        })
        .catch(() => {
          overlay.hide();
          Swal.fire({
            title: "Gagal melakukan pengaturan ulang kata sandi",
            text: "token tidak sesuai",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push("/login");
            }
          });
        });
    },
    changePassword() {},
  },
};
</script>
